import { createGlobalStyle } from "styled-components";

import SegoeUITtf from "../assets/fonts/segoeUI/Segoe UI.ttf";
import SegoeUIWoff from "../assets/fonts/segoeUI/Segoe UI.woff";
import SegoeUIBoldTtf from "../assets/fonts/segoeUI/Segoe UI Bold.ttf";
import SegoeUIBoldWoff from "../assets/fonts/segoeUI/Segoe UI Bold.woff";

export default createGlobalStyle`
body{
    @font-face {
    font-family: 'Segoe-UI-Regular';
    font-style: normal;
    font-weight: normal;
    src: url('${SegoeUIWoff}') format('woff'),
        url('${SegoeUITtf}') format('truetype');
    }

    @font-face {
    font-family: 'Segoe-UI-Bold';
    font-style: normal;
    font-weight: normal;
    src: url('${SegoeUIBoldWoff}') format('woff'),
        url('${SegoeUIBoldTtf}') format('truetype');
    }
}
`;
