/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react";
import ThemeContext, { themes } from "./src/context/theme";
import GlobalFonts from "@utils/font";

export const wrapRootElement = ({ element }) => (
  <ThemeContext.Provider value={themes.dark}>
    {" "}
    <GlobalFonts /> {element}
  </ThemeContext.Provider>
);
