import React, { useContext } from "react"

export const themes = {
  light: {
    foreground: "#111111",
    background: "#eeeeee",
    primary: "#FF4400",
  },
  dark: {
    foreground: "#eeeeee",
    background: "#222222",
    primary: "#FF4400",
  },
}

const ThemeContext = React.createContext(themes.light)

export const withTheme = Component => props => {
  const theme = useContext(ThemeContext)
  return <Component {...props} theme={theme} />
}

export default ThemeContext
